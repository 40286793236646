import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";

const OrderedList = styled.ol`
  counter-reset: item;
  & li {
    font-weight: bold;
    font-size: 18px;
  }
  & li table {
    font-weight: normal;
    font-size: 14px;
  }
  & li ol li {
  font-weight: normal;
  font-size: 14px;
}
}
`;

const StyledListElement = styled.li`
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  &:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  margin: 40px 0px;
  border: 1px solid black;
  & th {
    padding: 10px;
  }
  & td {
    padding: 10px;
  }
  & tr {
    border: 1px solid black;
  }
`;

const RevisionText = styled.p`
  margin: 40px 0px;
  text-align: center;
`;

const Terms = (props) => {
  return (
    <Layout>
      <Seo title="Terms & Conditions" />
      <PageHeader header="Terms & Conditions" />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
        >
          <Col lg={10} md={12}>
            <h4>SCHEDULE 1: GENERAL TERMS AND CONDITIONS </h4>
            <p>
              THESE CUSTOMER TERMS AND CONDITIONS (COLLECTIVELY, “
              <b>AGREEMENT</b>" OR "<b>Agreement</b>”) ENTERED INTO BY AND
              BETWEEN THE TRUSTFLIGHT LEGAL ENTITY SIGNING AN ORDER FORM AND ITS
              AFFILIATES (COLLECTIVELY, “<b>SERVICE PROVIDER</b>”, “
              <b>Service Provider</b>”, “<b>COMPANY</b>”, “<b>Company</b>”, "
              <b>WE</b>”, “<b>We</b>” OR “<b>US</b>”, “<b>Us</b>”) AND THE
              INDIVIDUAL OR LEGAL ENTITY USING OR LICENSING THE SERVICES UNDER
              THIS AGREEMENT AND/OR UNDER AN APPLICABLE ORDER FORM AND SERVICE
              DESCRIPTION DOCUMENT (COLLECTIVELY “<b>CUSTOMER</b>", “
              <b>Customer</b>", “<b>YOU</b>", "<b>You</b>", OR "<b>YOUR</b>", “
              <b>Your</b>” AND TOGETHER WITH THE SERVICE PROVIDER, THE “
              <b>PARTIES</b>”, “<b>Parties</b>”) GOVERNS CUSTOMER’S ACCESS AND
              USE OF THE SERVICES. FOR THE AVOIDANCE OF DOUBT, THE TERM
              “CUSTOMER” OR “YOU” SHALL ALSO INCLUDE EMPLOYEES OR OTHER
              INDIVIDUALS USING THE SERVICES. BY ACCEPTING THIS AGREEMENT WHILE
              EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, CUSTOMER
              AGREES TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO
              THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
              WARRANT AND REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
              ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH
              CASE THE TERMS “CUSTOMER” “YOU” OR “YOUR” SHALL REFER TO SUCH
              ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR
              IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT
              ACCEPT THIS AGREEMENT AND SHALL NOT BE PERMITTED TO USE THE
              SERVICES. BY ACCEPTING THE TERMS OF THIS AGREEMENT YOU REPRESENT
              AND WARRANT THAT ANY AND ALL INFORMATION YOU PROVIDE US THROUGH
              THE SERVICES IS TRUE, ACCURATE AND COMPLETE. THE PROVISION OF
              FALSE OR FRAUDULENT INFORMATION IS STRICTLY PROHIBITED.
            </p>
            <OrderedList>
              <StyledListElement>
                General
                <OrderedList>
                  <StyledListElement>
                    The Customer will subscribe to the Services (defined later)
                    by executing an order form (“<b>Order Form</b>”) on a date
                    (“<b>Commencement Date</b>”) which shall define the (i)
                    subscription term (“<b>Term</b>”); (ii) description of the
                    services provided (“<b>Service(s)</b>”); (iii) the
                    consideration to be paid by the Customer (“<b>Fees</b>”);
                    (iv) the period covered by each payment (“
                    <b>Payment Period</b>”); and (v) any other subscription
                    terms that may be agreed upon by the Parties.
                  </StyledListElement>
                  <StyledListElement>
                    This Agreement will automatically extend at the end of the
                    Term and at the end of each Renewal Period (defined later)
                    by successive periods of twelve calendar months (each a “
                    <b>Renewal Period</b>”) unless written notice of termination
                    is given by either Party to the other not less than one
                    calendar month in advance of the end of the Term, or the
                    then current Renewal Period.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Fees and Payment
                <OrderedList type="1">
                  <StyledListElement>
                    The Service Provider’s first invoice will be issued on
                    signature of the Order Form and is payable immediately on
                    receipt. The first invoice will include the first full
                    Payment Period and any pro-rated Fees if the Commencement
                    Date does not fall on the first day of the Payment Period.
                    The Service Provider will not provide any Service(s) until
                    the first invoice is paid in full by the Customer.
                  </StyledListElement>

                  <StyledListElement>
                    All ongoing Service(s) are invoiced in advance and due on
                    the first day of the Payment Period. The Customer will make
                    payment as stipulated in the Order Form to the Service
                    Provider’s nominated account in order that the Fees are
                    received by the Service Provider in cleared funds by the
                    first working day of the applicable Payment Period. (For the
                    avoidance of doubt a “<b>working day</b>” shall mean a day,
                    other than a Saturday, Sunday or public holiday in England,
                    when banks in London are open for business). If after the
                    Commencement Date, any change in Service(s) or Fees occurs
                    within a Payment Period, the invoice for the subsequent
                    Payment Period will include a pro-rated amount for any
                    additional charges in the preceding Payment Period.
                  </StyledListElement>

                  <StyledListElement>
                    All Fees are exclusive of any value added tax (VAT), sales
                    tax and other charges unless specified on the invoice. Any
                    applicable taxes or charges imposed on the Customer,
                    including bank fees and withholding taxes, are the
                    responsibility of the Customer.
                  </StyledListElement>

                  <StyledListElement>
                    Any Fees invoiced in arrears are due 15 days after the
                    invoice issue date unless otherwise specified on the
                    invoice.
                  </StyledListElement>

                  <StyledListElement>
                    Invoice disputes must be notified in writing to
                    accounts@trustflight.com within 10 days from the invoice
                    date. Only the disputed amounts within an invoice may be
                    withheld by the Customer, all other Fees must still be paid
                    in accordance with invoice terms. For any sums found to be
                    owed to the Customer as a result of a dispute, a credit will
                    be issued by the Service Provider on the invoice for the
                    next scheduled Payment Period.
                  </StyledListElement>

                  <StyledListElement>
                    If the Customer does not pay an invoice in full within the
                    time period specified in this Agreement, without prejudice
                    to any other remedy available to it, the Service Provider
                    may (at its sole discretion): i) charge interest on
                    outstanding amounts at a rate of 4% per month and ii)
                    suspend the Customer’s use of any Service(s) provided by the
                    Service Provider. Such suspension shall not relieve the
                    Customer of any obligation to pay Fees that accrued prior
                    to, during or after such suspension inclusive of any
                    interest. Unless instructed otherwise, payments will be
                    allocated chronologically to outstanding invoices.
                  </StyledListElement>

                  <StyledListElement>
                    During the Term the Service Provider may increase the Fees
                    on an annual basis with effect from each anniversary of the
                    Commencement Date. Fee increases will be calculated based on
                    the latest United Kingdom Consumer Prices Index as published
                    by the Office for National Statistics or 10%, whichever is
                    higher. Service Provider will provide at least 30 days’
                    notice to the Customer prior to any increase in Fees.
                  </StyledListElement>
                  <StyledListElement>
                    For the purpose of this Agreement, “<b>Aircraft</b>” shall
                    mean any aircraft operated under the Customer’s Operator’s
                    Certificate and all aircraft managed or operated by the
                    Customer on behalf of third parties. “<b>Bay(s)</b>” shall
                    mean a single area used by the Customer for conducting
                    maintenance on one airplane or housing one simulator
                    training device. “<b>Line Station(s)</b>” shall mean an
                    airport location where the Customer provides maintenance
                    support. “<b>Organisation(s)</b>”, “<b>Entities</b>” or “
                    <b>AOC(s)</b>” when used in the context of Fees, shall mean
                    a distinct operation of the Customer holding its own
                    approvals, licence, certification or equivalent. Fees may be
                    billed on a variable number of Aircraft, Bay(s), Line
                    Station(s), Organisation(s), Entities or AOC(s) used by the
                    Customer, collectively referred to as (“<b>Units</b>”)
                    within this Agreement.
                  </StyledListElement>
                  <StyledListElement>
                    Customer must notify Service Provider at least 30 days in
                    advance of any changes to the number of Units. Service
                    Provider retains the right to, at any time, independently
                    audit and verify the number of Units applicable to a
                    Customer. Upon a change in the number of Units, Service
                    Provider will issue an invoice or credit pro-rated for the
                    period at which the change was effective or identified until
                    the start of the next scheduled Payment Period. For the
                    avoidance of doubt, credits will not be issued against any
                    Units that fall below a minimum order quantity as stated on
                    the Order Form (“<b>Minimum Order Quantity</b>”).
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Customer Data
                <OrderedList type="1">
                  <StyledListElement>
                    Subject to Schedule 1, Clause 3 all vested, contingent and
                    future intellectual property rights, including copyright,
                    trade marks, service marks, design rights (whether
                    registered or unregistered), patents, know-how, trade
                    secrets, inventions, get-up and database rights,
                    collectively (“<b>Intellectual Property Rights</b>”),
                    subsisting in data belonging to the Customer or to third
                    parties and used by the Customer under licence (“
                    <b>Customer Data</b>”) are and shall remain the property of
                    the Customer.
                  </StyledListElement>

                  <StyledListElement>
                    Certain Customer Data may belong to third parties. In such
                    cases, the Customer warrants that all such Customer Data is
                    used with the consent of relevant third parties and the
                    Customer has obtained all necessary licences for the use of
                    the same.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Privacy and Data Protection
                <OrderedList type="1">
                  <StyledListElement>
                    For the purpose of providing the Service(s), the ongoing
                    operation thereof, and/or for security purposes, Service
                    Provider collects, processes and stores certain information
                    relating to an identified or identifiable natural person ("
                    <b>Personal Data</b>”) including data relating to all
                    members of the Customer’s organisation, and external users
                    under responsibility of the Customer, using the Service(s)
                    (“<b>User(s)</b>”). Service Provider respects your privacy
                    and is committed to protecting information shared by the
                    Customer. Service Provider’s policy and practices and the
                    type of information collected are further described in
                    Service Provider’s Privacy Policy and Data Processing
                    Addendum (“<b>DPA</b>”) (each to the extent applicable),
                    which are hereby incorporated by reference to this
                    Agreement.
                  </StyledListElement>

                  <StyledListElement>
                    The Parties shall comply with all applicable laws and
                    regulations pertaining to data security and privacy and
                    shall collect Personal Data only in a lawful manner. Service
                    Provider shall use reasonable measures to protect Customer
                    Data, which shall however be at least compliant with ISO
                    27001. Customer warrants that such data shall have been
                    obtained and shall be provided or made available to Service
                    Provider in compliance with all applicable laws and
                    regulations, including by obtaining all necessary consents
                    or other appropriate legal basis as required under
                    applicable data protection laws from data subjects whose
                    Personal Data is provided or made available, as may be
                    necessary to permit the Service Provider to provide the
                    Service and perform its obligations under this Agreement.
                  </StyledListElement>

                  <StyledListElement>
                    Service Provider shall: (i) process Personal Data only in
                    accordance with this Agreement and the DPA (as applicable
                    and as defined above); (ii) ensure persons authorised to
                    process the Personal Data have committed themselves to
                    confidentiality or are under an appropriate statutory
                    obligation of confidentiality and train such personnel to be
                    aware of their responsibilities under applicable laws and
                    this Agreement; (iii) reasonably assist Customer, at
                    Customer’s expense, as needed to cooperate with and respond
                    to requests from supervisory authorities, data subjects, or
                    customers by providing information related to Service
                    Provider’s practices of processing Personal Data; (iv)
                    notify the Customer without undue delay after becoming aware
                    of a security breach incident related to Customer’s and its
                    authorised User(s)’ Personal Data; (v) only process or use
                    Personal Data on its systems or facilities to the extent
                    necessary to provide the Service(s), perform its obligations
                    under the Agreement, the Privacy Policy and/or our DPA; (vi)
                    not lease, sell or otherwise distribute Personal Data other
                    then as part of the Service(s) agreed between the Parties
                    (excluding in case of assignment of this Agreement under its
                    terms and if legally required); (vii) purchase and maintain
                    throughout the Term or the term in which it retains the
                    Personal Data, professional liability insurance customary to
                    cover liabilities related to cyber security; (viii) appoint
                    a designated contact person who will be responsible for any
                    cooperation with the Customer with respect to the processing
                    of Personal Data; (ix) report to the Customer at its request
                    in writing regarding security and obligations related to
                    data processing and; (x) allow Customer to conduct an audit
                    regarding the processing of Personal Data by Service
                    Provider under this Agreement. For this purpose Service
                    Provider will, following reasonable prior written notice by
                    Customer, provide Customer with information reasonably
                    necessary for such purpose. Any such audit shall be subject
                    to appropriate non-disclosure agreements between the audit
                    performing personnel and Service Provider and within
                    reasonable intervals (but no more than once every 12 months)
                    to Service Provider. In case the Customer processes through
                    the Service(s) Personal Data of any EU citizens, the Parties
                    may execute a separate DPA governing such processing.
                  </StyledListElement>

                  <StyledListElement>
                    Customer shall be solely responsible for obtaining all
                    consents and authorisations as may be required by any
                    applicable law, for the collection, storage and processing
                    of Personal Data and/or sensitive Personal Data by Service
                    Provider according to Customer’s instructions. Service
                    Provider shall comply with all reasonable requests from the
                    Customer required by the Customer to maintain compliance
                    with their own laws and regulations relating to data
                    protection and privacy.
                  </StyledListElement>

                  <StyledListElement>
                    Service Provider shall notify Customer (via the Service(s)
                    or by written notice) of material changes in the Privacy
                    Policy, within the timeframe set in the Privacy Policy and
                    as further detailed therein. In the event the changes in the
                    Privacy Policy adversely or unlawfully change the material
                    rights of the authorised User(s), Customer will have the
                    right to reasonably object to such change.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Confidentiality
                <OrderedList type="1">
                  <StyledListElement>
                    The Service Provider shall use its reasonable endeavours to
                    ensure that all Customer Data is kept secure and
                    confidential.
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider shall not, in the absence of express
                    written consent from the Customer, disclose Customer Data to
                    any third party (other than a sub-contractor providing
                    services to the Service Provider in respect of the Service)
                    unless such disclosure is required by law or the rules of
                    any regulator with jurisdiction over the Service Provider.
                  </StyledListElement>

                  <StyledListElement>
                    Customer acknowledges that in order to provide and/or
                    further improve the Service(s), the Service Provider may
                    transform Customer Data to anonymised, aggregated
                    information (“<b>Anonymised Data</b>”). All Anonymised Data
                    shall not be considered as Customer Data to the extent such
                    data cannot reasonably be de-anonymised, and the Service
                    Provider may freely use such Anonymised Data, provided such
                    data does not link any individual or the Customer to the
                    Anonymised Data without Customer’s prior written approval.
                  </StyledListElement>

                  <StyledListElement>
                    In this Agreement, “<b>Confidential Information</b>” means
                    in respect of each Party all of its business, technical,
                    financial or other information disclosed to the other Party,
                    whether during the Term or prior. The following obligations
                    shall apply to the Party receiving Confidential Information
                    (the “<b>Receiving Party</b>”) from the other Party (the “
                    <b>Disclosing Party</b>”).
                  </StyledListElement>

                  <StyledListElement>
                    Subject to Schedule 1, Clause 5.6, the Receiving Party:
                    <OrderedList>
                      <StyledListElement>
                        may not use any Confidential Information for any purpose
                        other than the performance of its obligations under this
                        Agreement;
                      </StyledListElement>
                      <StyledListElement>
                        may not disclose any Confidential Information to any
                        third party except with the prior written consent of the
                        Disclosing Party; and
                      </StyledListElement>
                      <StyledListElement>
                        shall make every reasonable effort to prevent the
                        unauthorised use or disclosure of the Confidential
                        Information.
                      </StyledListElement>
                    </OrderedList>
                  </StyledListElement>

                  <StyledListElement>
                    The obligations of confidence referred to in this Clause 5
                    (other than Clause 5.1) shall not apply to any Confidential
                    Information that:
                    <OrderedList>
                      <StyledListElement>
                        is in the possession of and is at the free disposal of
                        the Receiving Party or is published or is otherwise in
                        the public domain prior to its receipt by the Receiving
                        Party;
                      </StyledListElement>
                      <StyledListElement>
                        is or becomes publicly available on a non-confidential
                        basis through no fault of the Receiving Party;
                      </StyledListElement>
                      <StyledListElement>
                        is required to be disclosed by any applicable law or
                        regulation; or
                      </StyledListElement>
                      <StyledListElement>
                        is received in good faith by the Receiving Party from a
                        third party who, on reasonable enquiry by the Receiving
                        Party claims to have no obligations of confidence to the
                        Disclosing Party in respect thereof and who imposes no
                        obligations of confidence upon the Receiving Party.
                      </StyledListElement>
                    </OrderedList>
                  </StyledListElement>

                  <StyledListElement>
                    Without prejudice to any other rights or remedies the
                    Disclosing Party may have, the Receiving Party acknowledges
                    and agrees that in the event of breach of this Clause the
                    Disclosing Party shall, without proof of special damage, be
                    entitled to seek an injunction or other equitable remedy for
                    any threatened or actual breach of the provisions of this
                    Clause in addition to any damages or other remedies to which
                    it may be entitled.
                  </StyledListElement>

                  <StyledListElement>
                    The obligations of the Parties under all provisions of this
                    Clause shall survive the expiry or the termination of this
                    Agreement irrespective of the reason for such expiry or
                    termination.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Warranties
                <OrderedList type="1">
                  <StyledListElement>
                    Service Provider warrants to Customer that (i) during the
                    term of each applicable Order Form, the Service(s) will
                    substantially confirm to the specifications as set forth in
                    such Order Form and Service Description Document; and (ii)
                    any support or implementation services performed by or on
                    behalf of Service Provider under this Agreement, if any,
                    will be performed in a professional and workmanlike manner
                    and by personnel that has the necessary skills, training and
                    background to perform such services.
                  </StyledListElement>

                  <StyledListElement>
                    The warranties contained in this Clause 6 are exclusive, and
                    are in lieu of all other warranties, express or implied,
                    including, without limitation, any implied warranty of
                    merchantability, fitness for a particular purpose, title, or
                    arising by a course of dealing or usage of trade. Service
                    Provider does not control the content uploaded to or via the
                    Services and, in particular, does not control the Customer
                    content and, as such, Service Provider does not give any
                    representation or warranty as to the accuracy, completeness,
                    currency, correctness, reliability, integrity, usefulness,
                    quality, fitness for purpose or originality of any of the
                    foregoing content or data. Customer acknowledges and agrees
                    that the Service(s) and any Service Provider content, and
                    any accompanying documentation are provided on an ‘as is’,
                    ‘as available’ basis. In particular, Service Provider does
                    not warrant that the operation of the Services will be
                    uninterrupted, contaminant-free or error-free, that system
                    availability will be maintained, that any error, bug or
                    problem be resolved of that they will meet the Customer’s
                    requirements. This Section does not limit, and is without
                    prejudice to, the provisions of Clause 7 below.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Liability
                <OrderedList type="1">
                  <StyledListElement>
                    This Clause 7 sets out the entire financial liability of the
                    Service Provider (including any liability for the acts or
                    omissions of its employees, agents and subcontractors) to
                    the Customer (and any person permitted to use the Service(s)
                    by the Customer) arising under or in connection with this
                    Agreement:
                  </StyledListElement>
                  <OrderedList type="1">
                    <StyledListElement>
                      in respect of any use made by the Customer of the
                      Service(s) or any part of them; and
                    </StyledListElement>
                    <StyledListElement>
                      in respect of any representation, misrepresentation
                      (whether innocent or negligent), statement or tortious act
                      or omission (including negligence) arising under or in
                      connection with this Agreement.
                    </StyledListElement>
                  </OrderedList>

                  <StyledListElement>
                    Except as expressly and specifically provided in this
                    Agreement:
                  </StyledListElement>
                  <OrderedList type="1">
                    <StyledListElement>
                      the Customer assumes sole responsibility for results
                      obtained from the use of the Service(s) by the Customer,
                      and for conclusions drawn from such use. The Service
                      Provider shall have no liability for any damage caused by
                      errors or omissions in any information, or instructions
                      provided to the Service Provider by the Customer in
                      connection with the Service(s), or any actions taken by
                      the Service Provider at the Customer's direction; and
                    </StyledListElement>
                    <StyledListElement>
                      all warranties, representations, conditions and all other
                      terms of any kind whatsoever implied by statute or common
                      law are, to the fullest extent permitted by applicable
                      law, excluded from this Agreement.
                    </StyledListElement>
                  </OrderedList>

                  <StyledListElement>
                    Nothing in this Agreement excludes the liability of the
                    Service Provider:
                  </StyledListElement>
                  <OrderedList type="1">
                    <StyledListElement>
                      for death or personal injury caused by the Service
                      Provider's negligence;
                    </StyledListElement>
                    <StyledListElement>
                      for fraud or fraudulent misrepresentation; or
                    </StyledListElement>
                    <StyledListElement>
                      for any other liability that cannot be excluded or limited
                      under applicable law.
                    </StyledListElement>
                  </OrderedList>

                  <StyledListElement>
                    Subject to Schedule 1, Clause 7.3:
                  </StyledListElement>
                  <OrderedList type="1">
                    <StyledListElement>
                      the Service Provider shall not be liable whether in tort
                      (including for negligence or breach of statutory duty),
                      contract, misrepresentation (whether innocent or
                      negligent), restitution or otherwise for any loss of
                      profits, loss of business, depletion of goodwill and/or
                      similar losses, or loss or corruption of data or
                      information (subject to Schedule 1, Clause 7.4.2), or pure
                      economic loss, or for any special, indirect or
                      consequential loss costs, damages, charges or expenses
                      however arising under this Agreement; and
                    </StyledListElement>
                    <StyledListElement>
                      the Service Provider's total aggregate liability in
                      contract, tort (including negligence or breach of
                      statutory duty), misrepresentation (whether innocent or
                      negligent), restitution or otherwise, arising in
                      connection with the performance or contemplated
                      performance of this Agreement shall be limited to an
                      amount equal to the Fees paid in the preceding six month
                      period, except in the case of breach of Schedule 2, Clause
                      3.2 (if applicable), where the Service Provider’s total
                      liability shall be limited to twelve months’ worth of fees
                      listed in the Order Form.
                    </StyledListElement>
                  </OrderedList>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Indemnity
                <OrderedList type="1">
                  <StyledListElement>
                    The Customer will fully indemnify the Service Provider
                    against all costs, expenses (including legal expenses),
                    liabilities, losses, damages and judgments that the Service
                    Provider may incur or be subject to as a result of the
                    Customer’s (or any Users’ or Non-Customer Users’) use or
                    misuse of the Service; or the Customer’s breach of this
                    Agreement (including any breach arising from the act or
                    omission of any User or Non-Customer User).
                  </StyledListElement>
                  <StyledListElement>
                    Service Provider shall defend, indemnify and hold Customer
                    and its members, officers, directors and employees harmless
                    from and against all damages, obligations, causes of action,
                    suits or injuries of any kind arising from any actual or
                    claimed infringement of copyright, patent, trademark and/or
                    other third-party intellectual property right as to the use
                    of the Application, ASP Infrastructure and/or any other
                    element of the Service by Customer pursuant to this
                    Agreement.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Customer Obligations
                <OrderedList type="1">
                  <StyledListElement>
                    The Customer shall (i) co-operate with the Service Provider
                    in all matters relating to the Service(s); (ii) provide, in
                    a timely manner, any materials or information required by
                    the Service Provider to deliver the Service(s) including
                    Customer Data, security access information and software
                    interfaces to the Customer's other business applications;
                    and (iii) obtain and maintain all necessary licences,
                    consents and comply with all relevant legislation in
                    relation to the Service(s).
                  </StyledListElement>

                  <StyledListElement>
                    The Customer shall not, without the prior written consent of
                    the Service Provider, at any time from the date of any
                    agreement to one year after the last date of supply of any
                    Service(s), solicit, entice away or employ any employee or
                    contractor of the Service Provider. The Customer shall be
                    liable for a fee of 100% of the annual remuneration of the
                    person involved for any breach of this Clause 9.2.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>

              <StyledListElement>
                Force Majeure
                <OrderedList type="1">
                  <StyledListElement>
                    Neither Party shall be in breach of this Agreement nor
                    liable for delay in performing, or failure to perform, any
                    of its obligations under this Agreement (other than an
                    obligation to pay Fees) if such delay or failure results
                    from events, circumstances or causes beyond its reasonable
                    control, including strikes, lock-outs or other industrial
                    disputes, failure of a utility service or transport or
                    telecommunications network, act of God, war, epidemic or
                    pandemic, riot, civil commotion, malicious damage,
                    compliance with any law or governmental order, rule,
                    regulation or direction, accident, breakdown of plant or
                    machinery, fire, flood, storm or default of suppliers or
                    subcontractors. In such circumstances the affected Party
                    shall be entitled to a reasonable extension of the time for
                    performing such obligations, provided that if the period of
                    delay or non-performance continues for 90 days, the Party
                    not affected may terminate this Agreement by giving 30 days'
                    written notice to the other Party.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>

              <StyledListElement>
                Termination
                <OrderedList type="1">
                  <StyledListElement>
                    Without prejudice to any other rights or remedies to which
                    the Parties may be entitled, either Party may terminate this
                    Agreement without liability to the other if:
                    <OrderedList type="1">
                      <StyledListElement>
                        the other Party commits a material breach of any of the
                        terms of this Agreement and (if such a breach is
                        remediable) fails to remedy that breach within 30 days
                        of that Party being notified in writing of the breach;
                      </StyledListElement>
                      <StyledListElement>
                        an order is made or a resolution is passed for the
                        winding up of the other Party, or circumstances arise
                        which entitle a court of competent jurisdiction to make
                        a winding-up order in relation to such other Party;
                      </StyledListElement>
                      <StyledListElement>
                        an order is made for the appointment of an administrator
                        to manage the affairs, business and property of the
                        other Party, or documents are filed with a court of
                        competent jurisdiction for the appointment of an
                        administrator of such other Party, or notice of
                        intention to appoint an administrator is given by such
                        other Party or its directors or by a qualifying floating
                        charge holder (as defined in paragraph 14 of Schedule B1
                        to the Insolvency Act 1986);
                      </StyledListElement>
                      <StyledListElement>
                        a receiver is appointed of any of the other Party's
                        assets or undertaking, or if circumstances arise which
                        entitle a court of competent jurisdiction or a creditor
                        to appoint a receiver or manager of such other Party, or
                        if any other person takes possession of or sells such
                        other Party's assets;
                      </StyledListElement>
                      <StyledListElement>
                        the other Party makes any arrangement or composition
                        with its creditors, or makes an application to a court
                        of competent jurisdiction for the protection of its
                        creditors in any way;
                      </StyledListElement>
                      <StyledListElement>
                        the other Party ceases, or threatens to cease, to trade;
                        or
                      </StyledListElement>
                      <StyledListElement>
                        the other Party takes or suffers any similar or
                        analogous action to any of the foregoing in any
                        jurisdiction applicable to it.
                      </StyledListElement>
                    </OrderedList>
                  </StyledListElement>

                  <StyledListElement>
                    On termination of this Agreement for any reason:
                    <OrderedList type="1">
                      <StyledListElement>
                        all licences granted under this Agreement shall
                        immediately terminate;
                      </StyledListElement>
                      <StyledListElement>
                        all outstanding invoices and Fees for Service(s)
                        rendered shall be immediately paid in full by the
                        Customer;
                      </StyledListElement>
                      <StyledListElement>
                        each Party shall return and make no further use of any
                        equipment, property, materials and other items (and all
                        copies of them) belonging to the other Party, including
                        Confidential Information of the other Party (provided
                        that Customer Data shall be dealt with in accordance
                        with Schedule 2, Clause 3.3 if applicable); and
                      </StyledListElement>
                      <StyledListElement>
                        the accrued rights of the Parties as at termination, or
                        the continuation after termination of any provision
                        expressly stated to survive or implicitly surviving
                        termination, shall not be affected or prejudiced.
                      </StyledListElement>
                    </OrderedList>
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>

              <StyledListElement>
                Miscellaneous
                <OrderedList type="1">
                  <a href={`mailto:${props.email}`}>{props.email}</a>
                  <StyledListElement>
                    <b>Notice</b> All notices under or in connection with this
                    Agreement shall be in writing in the English language and
                    shall be sent by e-mail to{" "}
                    <a href="mailto:notices@trustflight.com">
                      notices@trustflight.com
                    </a>
                    , and additionally provided via recorded mail to 34 Hamilton
                    Terrace, Leamington Spa, United Kingdom CV32 4LY. Notices
                    served by recorded may shall be deemed to have been received
                    on the second working day after it was sent; and notices
                    served by email shall be deemed to have been received at the
                    time of transmission.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Relationship of Parties</b> Nothing in this Agreement
                    shall create, or be deemed to create, a partnership, the
                    relationship of principal and agent, or of employer and
                    employee between the Service Provider and the Customer.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Assignment</b> Except as expressly provided in this
                    Agreement, the Customer shall not assign, transfer,
                    sub-contract, or in any other manner make over to any third
                    party the benefit and/or burden of this Agreement without
                    the prior written consent of the Service Provider. The
                    Service Provider may assign this Agreement to any entity
                    within the group with prior notice as long as there is no
                    material change to the Service provided.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Severance</b> The Parties agree that, in the event that
                    one or more of the provisions of this Agreement is found to
                    be unlawful, invalid or otherwise unenforceable, that/those
                    provisions shall be deemed severed from the remainder of
                    this Agreement. The remainder of this Agreement shall be
                    valid and enforceable.
                  </StyledListElement>
                  <StyledListElement>
                    <b>Entire Agreement</b> This Agreement embodies and sets
                    forth the entire agreement and understanding between the
                    Parties and supersedes all prior oral or written agreements,
                    understandings or arrangements relating to the subject
                    matter of this Agreement. Neither Party shall be entitled to
                    rely on any agreement, understanding or arrangement not
                    expressly set forth in this Agreement, save for any
                    representation made fraudulently.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Modifications to this Agreement</b> The Service Provider
                    reserves the right to modify or otherwise update this
                    Agreement at any time. Service Provider will notify the
                    Customer by e-mail of any changes. In the event the changes
                    in this Agreement adversely or unlawfully change the
                    material rights of the Customer, Customer will have the
                    right to reasonably object to such change for a period of 7
                    days after notice is provided by the Service Provider. If no
                    objection is received by the Service Provider and the
                    Service is continued to be used, Customer is deemed to have
                    accepted the updated Agreement. Customer may at any time
                    review the most current version of this Agreement at{" "}
                    <a href="https://trustflight.com/terms">
                      https://trustflight.com/terms.
                    </a>
                  </StyledListElement>

                  <StyledListElement>
                    <b>Third Party Rights</b> This Agreement does not confer any
                    rights on any person or party (other than the Parties to
                    this Agreement and (where applicable) their successors and
                    permitted assigns) pursuant to the Contracts (Rights of
                    Third Parties) Act 1999.
                  </StyledListElement>

                  <StyledListElement>
                    <b>No Waiver</b> The Parties agree that no failure by either
                    Party to enforce the performance of any provision in this
                    Agreement shall constitute a waiver of the right to
                    subsequently enforce that provision or any other provision
                    of this Agreement. Such failure shall not be deemed to be a
                    waiver of any preceding or subsequent breach and shall not
                    constitute a continuing waiver.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Marketing Rights</b> Unless otherwise notified in writing
                    by the Customer, the Service Provider may cite the Customer
                    as a user of the Service and a customer of the Service
                    Provider in the Service Provider’s promotional and
                    advertising literature, website, press releases, and in
                    proposals and communications with customers and prospective
                    customers. The Service Provider shall cease to cite the
                    Customer as a customer in materials created after the end of
                    the Term but shall not have any obligation to destroy
                    materials already printed or published, including by third
                    parties, which it shall be entitled to distribute.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Export Restrictions</b> The Service(s) and other
                    technology Service Provider makes available, and derivatives
                    thereof may be subject to export laws and regulations of the
                    United States and other jurisdictions. Each party represents
                    that it is not named on any U.S. government denied-party
                    list, or other governmental sanctions lists. Customer shall
                    not permit any User(s) to access or use any of the Services
                    in a U.S. or EU embargoed country or in violation of any
                    U.S. or EU export law or regulation.
                  </StyledListElement>

                  <StyledListElement>
                    <b>Governing Law & Jurisdiction</b> The law that will apply
                    in any dispute or lawsuit arising out of or in connection
                    with this Agreement, including its exhibits and the Order
                    Forms, the courts that have jurisdiction over any such
                    dispute or lawsuit, depend on the Company entity entering
                    into this Agreement as detailed below:
                  </StyledListElement>

                  <StyledTable>
                    <tr>
                      <th>Company Entity </th>
                      <th>Governing Law is:</th>
                      <th>Courts with exclusive jurisdiction are: </th>
                    </tr>
                    <tr>
                      <td>TRUSTFLIGHT LIMITED</td>
                      <td>England and Wales</td>
                      <td>London, England</td>
                    </tr>
                    <tr>
                      <td>TOTAL AOC SOLUTIONS LTD </td>
                      <td>England and Wales</td>
                      <td>London, England</td>
                    </tr>
                    <tr>
                      <td>TRUSTFLIGHT (CANADA) INC</td>
                      <td>British Columbia</td>
                      <td>Vancouver, British Columbia</td>
                    </tr>
                  </StyledTable>
                </OrderedList>
              </StyledListElement>
            </OrderedList>
            <h4>SCHEDULE 2: SOFTWARE TERMS AND CONDITIONS</h4>
            <OrderedList type="1">
              <StyledListElement>
                The Service
                <OrderedList type="1">
                  <StyledListElement>
                    Each Service is defined within a (“
                    <b>Service Description Document</b>”) which shall describe
                    (i) the modules available and core functionality; (ii)
                    implementation; and (iii) supported hardware/software to use
                    the Service(s). The Order Form specifies which software
                    modules will be provided to the Customer by the Service
                    Provider (“<b>Application</b>”).
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider shall, with effect from the
                    Commencement Date, provide the Service to the Customer on a
                    non-exclusive basis for the Term and in accordance with the
                    terms and conditions of this Agreement.
                  </StyledListElement>

                  <StyledListElement>
                    The Commencement Date shall be followed by an implementation
                    phase (for all agreed modules listed in the Order Form) as
                    specified within the applicable Service Description
                    Document(s).
                  </StyledListElement>

                  <StyledListElement>
                    The Customer shall cooperate with the Service Provider
                    during the implementation phase and provide such access to
                    its facilities as the Service Provider may reasonably
                    request for such purposes.
                  </StyledListElement>

                  <StyledListElement>
                    The Customer may submit requests for altered, enhanced or
                    extended functionality of the application (“
                    <b>Change Request</b>”). Change Requests will be reviewed by
                    the Service Provider and may be included in future updates
                    to the Application solely at the Service Provider’s
                    discretion. For the avoidance of doubt, the Customer
                    provides the Service Provider a fully paid-up perpetual,
                    irrevocable, worldwide and royalty-free licence to use any
                    content of a Change Request.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Training & Audit Support
                <OrderedList type="1">
                  <StyledListElement>
                    The Customer shall ensure that all User(s) are appropriately
                    trained and bear any costs associated with such training
                    over and above any training provided during the
                    implementation phase as described in the Service Description
                    Document.
                  </StyledListElement>

                  <StyledListElement>
                    Additional training outside of the implementation phase is
                    subject to the following fees: Remote: $150 per hour; On
                    site: $900 per day plus travel and expenses.
                  </StyledListElement>
                  <StyledListElement>
                    Without prejudice to Schedule 1, Clause 4.3, Service
                    Provider will provide standard, non-Customer specific
                    documentation to support Customer audit activities.
                    Additional audit support is subject to availability of
                    Service Provider personnel and the following fees: Remote:
                    $150 per hour; On site: $900 per day plus travel and
                    expenses.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Customer Data & Back-Ups
                <OrderedList type="1">
                  <StyledListElement>
                    The Service Provider shall make regular backups of all
                    Customer Data at least once daily. Such backups will be
                    stored at a location separate from the primary data.
                  </StyledListElement>

                  <StyledListElement>
                    In the event of any loss of damage to Customer Data, the
                    Service Provider shall use reasonable endeavours to restore
                    the lost or damaged Customer Data from the latest back-up of
                    such Customer. The Service Provider shall not be responsible
                    for any loss, destruction, alteration or disclosure of
                    Customer Data caused by any third party (except those third
                    parties sub-contracted by the Service Provider to perform
                    services related to Customer Data maintenance and back-up).
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider shall make accessible for download to
                    the Customer all Customer Data stored in the Application on
                    the effective date of termination. Customer Data will be
                    accessible for download for 30 days from the effective date
                    of termination, failing which the Service Provider may
                    destroy or otherwise dispose of such Customer Data after
                    such time.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Maintenance, Support and Upgrades
                <OrderedList type="1">
                  <StyledListElement>
                    The Service Provider shall be responsible for all
                    maintenance and upgrades to the Application which may from
                    time to time be required in the opinion of the Service
                    Provider. For the purposes of this Agreement, “<b>Defect</b>
                    ” shall be defined as a failure of the Application to
                    substantially perform as defined within the Service
                    Description Document including any errors or improper
                    outcomes. Should the Customer determine that the Application
                    includes a Defect, the Customer may at any time file error
                    reports and the Service Provider shall use reasonable
                    commercial endeavours promptly to correct any errors. During
                    maintenance periods, the Service Provider may, at its
                    discretion, upgrade versions, install error corrections and
                    apply patches to the Application.
                  </StyledListElement>

                  <StyledListElement>
                    Maintenance of the Application or other aspects of the
                    Service that may require interruption of the Service (“
                    <b>Maintenance Events</b>”) shall not be performed during
                    Normal Business Hours (“<b>Normal Business Hours</b>”
                    meaning 0600-2300 UK time, Monday-Friday excluding any UK
                    bank holidays) unless the Service Provider gives the
                    Customer no less than 48 hours’ prior notice. The Service
                    Provider may interrupt the Service to perform emergency
                    maintenance during the daily window of 2300-0200 UK time.
                    Any Maintenance Events that occur during Normal Business
                    Hours, and which were not requested or caused by, or
                    notified as referenced above in advance to, the Customer,
                    shall be considered downtime for the purpose of service
                    availability measurement. The Service Provider shall at all
                    times use reasonable endeavours to keep any service
                    interruptions to a minimum.
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider shall provide the Customer with
                    technical support services. The Customer shall notify in
                    writing to the Service Provider up to a maximum of 5
                    personnel to act as Internal Administrators (“<b>IA</b>”)
                    who shall be authorised to contact the Service Provider for
                    technical support services. The Service Provider shall
                    provide technical support services only to such specified
                    IA’s.
                  </StyledListElement>

                  <StyledListElement>
                    The IA’s are given access to TrustFlight Support 24/7, 365
                    days per year via: Telephone: +44 (0) 2038 555972; Email:
                    support@trustflight.com; Access to Customer Support Ticket
                    Portal: https://helpdesk.centrik.net
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider technical support call centre shall
                    respond to all support requests from IA’s within the time
                    periods specified below, according to priority.
                  </StyledListElement>

                  <StyledListElement>
                    The named IA’s and TrustFlight Support shall jointly
                    determine the priority of any Defect, and respond within the
                    specified time, using one of following priorities:
                  </StyledListElement>
                </OrderedList>
                <StyledTable>
                  <tr>
                    <th>Severity</th>
                    <th>Definition</th>
                    <th>
                      Response Time (From Service Provider being first notified)
                    </th>
                    <th>
                      Resolution Time (From Service Provider being first
                      notified)
                    </th>
                  </tr>
                  <tr>
                    <td>Critical</td>
                    <td>
                      Complete outage of system or serious degradation impacting
                      at least 80% of User(s)
                    </td>
                    <td>30 minutes</td>
                    <td>2 hours</td>
                  </tr>
                  <tr>
                    <td>High</td>
                    <td>
                      Limited degradation of system preventing use of key
                      features and impacting at least 20% of User(s) E.g.
                      User(s) are unable to submit reports
                    </td>
                    <td>1 hours</td>
                    <td>1 working day</td>
                  </tr>
                  <tr>
                    <td>Medium</td>
                    <td>
                      Recurring Defects affecting localised features that do not
                      meet the criteria above E.g. Managers are unable to filter
                      KPI report as desired
                    </td>
                    <td>2 hours</td>
                    <td>1 week</td>
                  </tr>
                  <tr>
                    <td>Low</td>
                    <td>
                      Minor Defects that do not prevent effective usage of the
                      system, or have ready workarounds
                    </td>
                    <td>1 day</td>
                    <td>No commitment</td>
                  </tr>
                </StyledTable>
              </StyledListElement>

              <StyledListElement>
                Service Level Agreement
                <OrderedList type="1">
                  <StyledListElement>
                    Subject to the exceptions contained in Schedule 2 Clauses 2,
                    4 and Schedule 1 Clause 10, the Service Provider shall
                    provide at least a 99.9% uptime service availability level
                    (“<b>Uptime Service Level</b>”). Downtime in this context
                    refers to an unplanned Critical Defect or a Maintenance
                    Event that was not notified to the Customer in accordance
                    Schedule 2, Clause 4.
                  </StyledListElement>

                  <StyledListElement>
                    The availability measurement is based on the monthly average
                    percentage availability, calculated at the end of each
                    calendar month as the total actual uptime minutes divided by
                    total possible uptime minutes in the month. The Service
                    Provider shall keep and shall send to the Customer, on
                    request, full records of its availability measurement
                    activities under this Agreement.
                  </StyledListElement>

                  <StyledListElement>
                    If availability falls below the Uptime Service Level in a
                    given calendar month (“<b>Service Delivery Failure</b>”),
                    the Service Provider shall credit the Customer's account by
                    an amount calculated as the product of the total cumulative
                    downtime (expressed as a percentage of the total possible
                    uptime minutes in the month concerned) and the total Fee for
                    that month (“<b>Service Credit</b>”).
                  </StyledListElement>
                  <StyledListElement>
                    The maximum Service Credit allowable in a given month is
                    limited to an amount equal to 50% of the total Fee for that
                    month.
                  </StyledListElement>

                  <StyledListElement>
                    The Customer acknowledges and agrees that Service Credits
                    constitute a genuine pre-estimate of the loss or damage that
                    the Customer would suffer as a result of the Service
                    Provider’s Service Delivery Failure and are not intended to
                    operate as a penalty for the Service Provider’s
                    non-performance. The Customer further acknowledges and
                    agrees that payment of Service Credits constitutes the
                    Customer’s exclusive remedy in respect of the Service
                    Provider’s Service Delivery Failure.
                  </StyledListElement>

                  <StyledListElement>
                    Sums paid as Service Credits to the Customer shall count
                    towards the Service Provider’s cap on its liability in
                    Schedule 1, Clause 7.4.2.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
              <StyledListElement>
                Software Licences & Application Terms of Use
                <OrderedList type="1">
                  <StyledListElement>
                    The Customer shall use the Application and its components
                    under a non-exclusive, non-transferable licence, as set out
                    in this Agreement. The Customer shall use, and shall procure
                    that all User(s) use the Application only as expressly
                    permitted in this Agreement. The Customer and User(s) shall
                    only use the Application exclusively for the purposes of
                    conducting their normal business.
                  </StyledListElement>

                  <StyledListElement>
                    Notwithstanding any functionality in the Application,
                    Customer agrees that the Application is provided only as a
                    tool, and must only be used in accordance with applicable
                    regulations and with appropriate backups, safeguards and
                    procedures in place. Customer is responsible for verifying
                    the accuracy and correctness of any information provided
                    through the Application. Regulatory compliance of the
                    Application is the sole responsibility of the Customer, and
                    the Service Provider provides no warranties on the
                    compliance of the Application or Service(s).
                  </StyledListElement>

                  <StyledListElement>
                    The Service Provider warrants that the Application and its
                    components are either its property, or that it has the
                    requisite authority to sub-licence the Application and its
                    components to the Customer for the purposes of this
                    Agreement. Nothing in this Agreement shall transfer any
                    Intellectual Property Rights or other rights in the
                    Application or the Service(s) to the Customer, all of which
                    remain the property of the Service Provider or its
                    licensors.
                  </StyledListElement>

                  <StyledListElement>
                    User(s)’ access to the Application shall be controlled by
                    means of authentication by user name and password which must
                    be kept confidential. The Customer is responsible for
                    maintaining the User(s) access to the Application. This
                    includes adding and removing Users, managing User(s)
                    permissions, and changing User(s) passwords.
                  </StyledListElement>

                  <StyledListElement>
                    The Customer shall not:
                    <OrderedList type="1">
                      <StyledListElement>
                        attempt to copy, duplicate, modify, create derivative
                        works from or distribute all or any portion of the
                        Application except to the extent expressly set out in
                        this Agreement or as may be allowed by any applicable
                        law which is incapable of exclusion by agreement between
                        the Parties;
                      </StyledListElement>
                      <StyledListElement>
                        store, distribute or transmit any thing or device
                        (including any software, code, file or programme) which
                        may prevent, impair or otherwise adversely affect: the
                        operation of any computer software, hardware or network,
                        any telecommunications service, equipment or network or
                        any other service or device; access to or the operation
                        of any programme or data, including the reliability of
                        any programme or data (whether by rearranging, altering
                        or erasing the programme or data in whole or part or
                        otherwise); or adversely affect the user experience,
                        including worms, trojan horses, viruses and other
                        similar things or devices.
                      </StyledListElement>
                      <StyledListElement>
                        store, distribute or transmit any material through the
                        Service that is unlawful, harmful, threatening,
                        defamatory, obscene, infringing, harassing or racially
                        or ethnically offensive; facilitates illegal activity;
                        depicts sexually explicit images; or promotes unlawful
                        violence, discrimination based on race, gender, colour,
                        religious belief, sexual orientation, disability, or any
                        other illegal activities;
                      </StyledListElement>
                      <StyledListElement>
                        attempt to reverse compile, disassemble, reverse
                        engineer or otherwise reduce to human-perceivable form
                        all or any part of the Application, except as may be
                        allowed by any applicable law which is incapable of
                        exclusion by agreement between the Parties;
                      </StyledListElement>
                      <StyledListElement>
                        access all or any part of the Application or the Service
                        in order to build a product or service which competes
                        with the Application and/or the Service;
                      </StyledListElement>
                      <StyledListElement>
                        use the Application or Service to provide services to
                        third parties;
                      </StyledListElement>
                      <StyledListElement>
                        save in respect of User(s) and Non-Customer User(s)
                        attempt to obtain, or assist third parties in obtaining,
                        access to the Application and/or the Service.
                      </StyledListElement>
                    </OrderedList>
                  </StyledListElement>
                  <StyledListElement>
                    The Service Provider shall monitor the Customer’s, User(s)’
                    and Non-Customer User(s)’ use of the Application from time
                    to time to ensure compliance with the terms and conditions
                    of this Agreement.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>

              <StyledListElement>
                Custom Development
                <OrderedList type="1">
                  <StyledListElement>
                    Customer may request the Service Provider to develop
                    additional functionality, modifications, enhancements,
                    integrations or other such developments to the application
                    (“<b>Custom Development</b>”). Custom Development shall be
                    defined in a separate Statement of Work (“<b>SoW</b>”)
                    containing i) description of the Custom Development; ii)
                    Acceptance Criteria; iii) Estimated Days to Develop and is
                    agreed to by the Customer and Service Provider prior to
                    commencement of any work.
                  </StyledListElement>

                  <StyledListElement>
                    Custom Development work is billed on a time and materials
                    basis (“<b>Development Fee</b>”), in advance, based on an
                    estimate of the work involved. Once the SoW is completed,
                    Service Provider will issue a final invoice or credit note
                    as appropriate. Custom Development is billed at a daily rate
                    of $1500. Once completed, an annual maintenance fee of 20%
                    of the Development Fee will be charged for maintenance (“
                    <b>Maintenance Fee</b>”).
                  </StyledListElement>

                  <StyledListElement>
                    Custom Development work will be completed based on
                    availability of Service Provider’s resource at the time of
                    request. Service Provider reserves the right to reject any
                    Custom Development requests for any reason. For the
                    avoidance of doubt, all intellectual property created as
                    part of Custom Development is property of the Service
                    Provider, and the Service Provider provides a non-exclusive
                    licence to the Customer for the Term of the Agreement,
                    provided all Development and Maintenance Fees are paid.
                  </StyledListElement>
                </OrderedList>
              </StyledListElement>
            </OrderedList>
            <RevisionText>
              Revision 1.1, Last Updated 2023-09-23.{" "}
              <a href="https://trustflight.com/terms-v1.0">See Previous</a>
            </RevisionText>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Terms;
